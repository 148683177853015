import React from 'react';
import '../App.css';

function Contact() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Contact Me!
        </p>
      </header>
    </div>
  );
}

export default Contact;
