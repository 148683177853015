import React from 'react';
import '../App.css';
import logo from './../assets/logo_transparent.png';

export default function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <img src={logo} alt="Logo" className="App-logo"></img>
          {/*<br/><a>About</a> | <a>Contact</a> | <a>Resume</a>*/}
          <br/>
          <p>A Podcast about Onewheels and the people who ride them...</p>
          <b>Coming Soon!</b>
        </p>
        <br/>
      </header>
        <footer className="App-footer ">A <a href="https://daklabb.dev">DAKLabb</a> production</footer>
    </div>
  );
}
